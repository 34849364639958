import React from 'react';
import bambooBg from '../../images/bambutton.svg';

function BambooButton() {
  const handleClick = () => {
    window.open('https://app.acuityscheduling.com/schedule.php?owner=33909911', '_blank');
  };

  return (
    <button className="bamboo-button" onClick={handleClick}>
      <img src={bambooBg} alt="" className="bamboo-bg" />
      <span className="button-text">BOOK WITH US</span>
    </button>
  );
}

export default BambooButton;