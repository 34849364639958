import React, { useState } from 'react';

const AccordionItem = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="accordion-item">
      <button 
        className={`accordion-header ${isOpen ? 'open' : ''}`}
        onClick={() => setIsOpen(!isOpen)}
      >
        {question}
        <span className="accordion-icon">{isOpen ? '−' : '+'}</span>
      </button>
      <div className={`accordion-content ${isOpen ? 'open' : ''}`}>
        {answer}
      </div>
    </div>
  );
};

const Accordion = () => {
  const faqs = [
    {
      question: "What is Mele Kalikimaka?",
      answer: "A magical 5 course Holiday Tiki experience hidden away in a top secret backyard location"
    },
    {
      question: "Where are we?",
      answer: "Near the intersection of Slauson and La Brea. The location will be texted to you the day of your reservation. Street parking is available, however we strongly encourage you to use Uber or Lyft. Please wait by the gate upon arrival and we will let you in."
    },
    {
      question: "Do any of the drinks contain allergens?",
      answer: "Some of our selections contain dairy or nuts. Currently we are unable to make any substitutions. Apologies for the inconvenience."
    }
  ];

  return (
    <div className="accordion-section">
      {faqs.map((faq, index) => (
        <AccordionItem 
          key={index}
          question={faq.question}
          answer={faq.answer}
        />
      ))}
    </div>
  );
};

export default Accordion;