import React from 'react';
import Header from './components/layout/Header';
import snowglobeLogo from './images/Snowglobe-logo.svg';
import snowShelf from './images/snow-shelf.svg';
import BambooButton from './components/layout/BambooButton';
import Snow from './components/effects/Snow';
import Accordion from './components/layout/Accordion';
import './styles/global.css';

function App() {
  return (
    <div className="app">
      <Snow />
      <Header />
      <main>
        <section id="home" className="section">
          <img src={snowglobeLogo} alt="Snowglobe Logo" className="home-logo" />
          <h1 className="subheader slanted-text">Mele<br/>Kalikimaka</h1>
          <p className="holiday">at Savage Seas</p>
        </section>
        
        <section id="divider" className="divider-section">
          <img src={snowShelf} alt="Snow Shelf Divider" className="snow-shelf" />
        </section>
        
        <section id="about" className="section">
          <p>Long ago, in a forgotten corner of the sea, a shipwrecked crew found themselves stranded on a mysterious island with no way to signal for rescue. But despite the isolation, the crew’s spirits remained unbroken, for the island seemed to hold an odd kind of magic. As December’s winds began to stir, a lush grove of palm trees shimmered under the glow of an otherworldly mist, revealing a hidden tiki hut, complete with twinkling fairy lights, carved wooden totems, and a hidden storage of exotic rums. The castaways, ever resourceful, decided to make the best of their predicament. They fashioned Christmas decorations with what they could find, strung up shells and lanterns, and decorated the bar with makeshift ornaments. As Christmas approaches, a peculiar enchantment washes over the island—palm fronds sway to the tune of distant carolers, and the soft sound of bells could be heard echoing across the waves. It was as if the island itself had decided to celebrate the season.</p>
          <p>Every December since, the shipwrecked souls gather at the magical hut, sipping holiday cheer and telling tales of Christmases past, knowing that no matter where they are, Christmas always finds its way to those who believe in the magic of the season.</p>
          <Accordion />
          
          <div className="button-wrapper">
            <BambooButton />
          </div>
        </section>
      </main>
      <footer>
        <div className="footer-section">
          <p className="section-headline">Questions?</p>
          <a 
            href="https://instagram.com/direct/t/savageseastiki"
            onClick={(e) => {
              e.preventDefault();
              
              // Try multiple deep linking formats for better iOS compatibility
              const urls = [
                'instagram://direct/t/savageseastiki',
                'instagram://direct/inbox/t/savageseastiki',
                'instagram://user?username=savageseastiki',
                'https://instagram.com/direct/t/savageseastiki'
              ];

              // Try to open app with each URL format
              let urlIndex = 0;
              const tryNextUrl = () => {
                if (urlIndex < urls.length) {
                  window.location.href = urls[urlIndex];
                  urlIndex++;
                  setTimeout(tryNextUrl, 25);
                }
              };

              tryNextUrl();
            }}
            className="instagram-dm-button"
          >
            Message Us
          </a>
          <p className="questions-text">or DM us @savageseastiki</p>
        </div>

        <div className="footer-section">
          <p className="section-headline">SOCIALS</p>
          <div className="social-icons">
            <a href="https://www.instagram.com/savageseastiki/" target="_blank" rel="noopener noreferrer">
              <div className="circle-icon instagram"></div>
            </a>
          </div>
        </div>

        <p className="copyright">&copy; {new Date().getFullYear()} Savage Seas Tiki. All rights reserved.</p>
      </footer>
    </div>
  );
}

export default App; 