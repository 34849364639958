import React from 'react';
import palms from '../../images/palms.svg';

const Header = () => {
  return (
    <header className="header">
      <img src={palms} alt="Palm Trees" className="header-palms" />
    </header>
  );
};

export default Header;